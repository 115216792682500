@import "primereact/resources/themes/lara-dark-blue/theme.css"; /* Lara Dark theme */
@import "primereact/resources/primereact.min.css"; /* PrimeReact core CSS */
@import "primeicons/primeicons.css"; /* PrimeIcons for icons */
@import "primeflex/primeflex.css"; /* Optional: PrimeFlex for utility classes */

/* Import the Inter font from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

/* PrimeReact Theme Overrides */
body {
  font-family: 'Inter', sans-serif; /* Apply modern font */
  margin: 0;
  padding: 0;
  background-color: #000; /* Ensure global dark theme */
  color: #FFF; /* Default white text for the dark theme */
  box-sizing: border-box;
}

/* Optional: Override PrimeReact components with consistent styling */
button, input, select, textarea {
  font-family: 'Inter', sans-serif; /* Ensure inputs use the new font */
}

.custom-spinner {
    --spinner-color: #fff; /* Set the spinner color to white */
  }
  
  .custom-spinner > svg {
    stroke: #fff !important; /* Override spinner stroke color to white */
  }
    

